import React from 'react'
import '../styles/Home.css'
import BookCards from '../components/BookCards'

export default function Home() {
  return (
    <div id='home'>
      <BookCards/>
    </div>
  )
}
